	
<template>
  <main class="faq">
    <section v-if="loaded">
      <h3>FAQ</h3>
      <h4>General</h4>
      <ul>
        <li
          v-for="(question, index) in questions.qanda"
          :key="index"
          @click="toggleQuestion('gen' + index)"
          :class="{ expanded: expandedQuestion == 'gen' + index }"
        >
          <span class="chevron"
            ><Chevron2
              :direction="expandedQuestion == 'gen' + index ? 'up' : 'down'"
          /></span>

          <prismic-rich-text :field="question.question" />
          <span class="mt-2 d-block">
            <slide-up-down
              :active="expandedQuestion == 'gen' + index"
              :duration="250"
              class="w-100"
            >
              <prismic-rich-text :field="question.answer" />
            </slide-up-down>
          </span>
        </li>
      </ul>
      <h4>For organisers</h4>
      <ul>
        <li
          v-for="(question, index) in questions.payments"
          :key="index"
          @click="toggleQuestion('org' + index)"
          :class="{ expanded: expandedQuestion == 'org' + index }"
        >
          <span class="chevron"
            ><Chevron2
              :direction="expandedQuestion == 'org' + index ? 'up' : 'down'"
          /></span>
          <prismic-rich-text :field="question.question" />
          <span class="mt-2 d-block">
            <slide-up-down
              :active="expandedQuestion == 'org' + index"
              :duration="250"
              class="w-100"
            >
              <prismic-rich-text :field="question.answer" /> </slide-up-down
          ></span>
        </li>
      </ul>
      <h4>For players</h4>
      <ul>
        <li
          v-for="(question, index) in questions.registration"
          :key="index"
          @click="toggleQuestion('play' + index)"
          :class="{ expanded: expandedQuestion == 'play' + index }"
        >
          <span class="chevron"
            ><Chevron2
              :direction="expandedQuestion == 'play' + index ? 'up' : 'down'"
          /></span>
          <prismic-rich-text :field="question.question" />
          <span class="mt-2 d-block">
            <slide-up-down
              :active="expandedQuestion == 'play' + index"
              :duration="250"
              class="w-100"
            >
              <prismic-rich-text :field="question.answer" /> </slide-up-down
          ></span>
        </li>
      </ul>
    </section>
    <section class="foot">
      <h5>Can't find the answer you are looking for?</h5>
      <p class="foot-sub-title">
        Send us an <a href="mailto:hello@tjing.se">email</a> or give us a call -
        we'd love to help answer it.
      </p>
    </section>
  </main>
</template>
 
<script>
import Chevron2 from "@/assets/icons/Chevron2";
import SlideUpDown from "vue-slide-up-down";

export default {
  name: "FAQ",
  components: { Chevron2, SlideUpDown },
  data() {
    return {
      loaded: false,
      temp: null,
      questions: [],
      expandedQuestion: null,
      showDetails: false,
    };
  },
  methods: {
    toggleQuestion(question) {
      this.expandedQuestion == question
        ? (this.expandedQuestion = null)
        : (this.expandedQuestion = question);
    },
    getContent() {
      this.$prismic.client
        .getSingle("faq")
        .then((document) => {
          if (document) {
            this.questions = document.data;
            this.loaded = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getContent();
  },
};
</script>

<style lang="scss">
.foot {
  margin-top: 24px;
  border-top: 1px solid $blizzard;
  padding: 32px 15px;
  text-align: center;
  h5 {
    font-size: 16px;
  }
  p {
    font-size: 14px;

    a {
      color: $ocean;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.faq {
  min-height: 80vh;

  h4 {
    font-size: 22px;
    @include Gilroy-Regular;

    margin-top: 12px;
    color: $strom;
    padding-top: 24px;
    border-top: 1px solid $strom;
    width: calc(100% - 30px);
    margin: 0 15px;
    margin-bottom: 12px;
  }

  h3 {
    display: flex;
    font-size: 48px;
    width: 100%;
    justify-content: center;
    margin: 64px 0;
    @include Gilroy-Bold;
  }

  ul {
    list-style: none;
    padding: 0;
    ul {
      list-style: disc;
      padding-left: 40px;
      padding-right: 30px;
      li {
        padding-left: 0px;
        font-size: 16px;
      }
    }
    ol {
      padding-top: 8px;
      li {
        padding: 0 0px 8px 0;
      }
    }
    li {
      font-size: 14px;
      line-height: unset;
      padding-right: 30px;
      position: relative;
      padding: 15px 0;
      transition: all ease 0.3s;

      &:hover {
        background-color: $mist;
      }
      .chevron {
        position: absolute;
        right: 15px;
        top: 18px;
      }

      p {
        font-size: 16px;
        margin: 0;
        padding: 0 15px;

        strong {
          transition: all ease 0.3s;
          display: inline-flex;
          @include Gilroy-Bold;
          font-size: 18px;
          padding-right: 25px;
        }
      }
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .foot {
    border-top: 1px solid $strom;
    margin: 32px 15px 0px 15px;
    padding-top: 48px;
    text-align: center;
    h5 {
      font-size: 20px;
    }
    p {
      font-size: 16px;

      a {
        color: $ocean;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .faq {
    max-width: 600px;
    margin: auto;
    h3 {
      font-size: 64px;
      margin-left: 0;
      margin-right: 0;
    }

    h4 {
      font-size: 24px;
      padding-bottom: 24px;
      margin-left: 15px;
      margin-right: 15px;
    }

    ul {
      ul {
        padding-left: 24px;
        li {
          font-size: 18px;
        }
      }
      li {
        padding-left: 15px;
        margin-top: 0px;
        transition: all ease 0.3s;

        .chevron {
          top: 22px;
          cursor: pointer;
          svg {
            width: 18px;
            height: auto;
          }
        }

        &.expanded {
          background-color: $mist;
          transition: all ease 0.3s;

          strong {
            transition: all ease 0.3s;
            padding-bottom: 5px;
          }
        }
        p {
          font-size: 18px;
          padding-left: 0;
          padding-right: 30px;

          strong {
            cursor: pointer;
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>

